@media screen and (max-width: 950px) {
    .availabilityContainer {
      flex-direction: column !important;
      width: 100% !important;
    }
    
    .addSlotBtn{
        margin-left: 0 !important;
    }


    .daysOfWeek{
        border: 1px solid grey !important;
        padding: .5rem !important;
        
        
    }
  }

  .addBlockedSlots input{
    width: 120px !important;
  }

  .availabilityContainer .p-button:enabled:hover {
    color: black !important;
    border: 1px solid grey;
  }