

.containerDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;

}

@media screen and (max-width: 1200px) {
    .navigationClass{
        display: none !important;
    }

    .formDiv{
        justify-content: center !important;
    }
  }

@media screen and (max-width: 850px) {
    .profileContainer {
      flex-direction: column !important;
    }
    .profileViewDivs{
        width: 100% !important;
    }
  }

.profileViewDivs p, div{
    word-wrap: break-word;
}

.navigationClass a {
    text-decoration: none !important;
    color: black ;
}

.navigationClass a:hover {
    color: green ;
}

.navigationClass{
    position: sticky !important;
    top: 120px !important;
}

.formDiv textarea,
.formDiv input{
    /* background: transparent !important; */
    color: black !important;
    border: 1px solid grey !important;
    box-shadow: none !important;
}

/* .formDiv .p-dialog-footer{
    background: #212121fa !important;
} */