.dashboard-card-container{
    display: grid;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 1rem;
    position: relative;
}

@media screen and (min-width: 1024px) {
    .dashboard-card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }
}

/* For even larger screens (e.g., large TVs) */
@media screen and (min-width: 1440px) {
    .dashboard-card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
    }
}

.dashboard-filter-container{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-bottom: 3rem;
}

.dash-card-container {
    flex: 1;
    flex-basis: 200;
    /* background: #0f173e3d; */
    /* width: 48%;
    border-radius: 10px;
    margin-bottom: 1rem;
    justify-content: center!important;
    display: flex!important; */
}

.filter-card-container{
    flex: 1;
    flex-basis: 200;
    /* background: #0f173e3d; */
}

.full-width{
    width: 100%;
}

.card-footer{
    display: flex;
    justify-content: end;
    gap: .5rem;
}

.dashboard-card{
    width: 100%;
    background-color: #d9d9d9;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 11rem;
}
/* 
.leads-card{
    background-color: #0f173e!important;
    color: #ababab;
}

.replies-card{
    background-color: #da474e!important;
}

.call-card{
    background-color: #D3D3D3!important;
}

.opp-card{
    background-color: #777b92!important;
}

.pre-card{
    background-color: #eeabae!important;
}

.app-card{
    background-color: #898fd3!important;
}

.shoppers-card{
    background-color: #86acd1!important;
} */

.leads-card .p-card-subtitle:nth-child(2) {
    color: #ababab!important;
  }

.dashboard-card .p-card-subtitle:nth-child(2) {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    font-weight: 1000;
    margin-bottom: 0.5rem;
    /* color: black; */
  }

.dashboard-card .p-card-content:nth-child(3) {
    padding: 0!important;
  }
.dashboard-percentage{
    display: flex;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.language-multiselect{
    width: 100%;
}

.form-card {
    background: #0f173e3d;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}
.form-justify-content-center {
    justify-content: center!important;
}
.form-flex {
    display: flex!important;
}

.form-gap-2 {
    gap: 0.5rem!important;
}
.form-flex-column {
    flex-direction: column!important;
}

.settings-add-button {
    background: #000000;
    border: 1px solid #000000;
}

.settings-add-button:hover {
    color: #ffffff;
    background: #222222!important;
    border: 1px solid #222222!important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5) as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of everything */
  }
  
  .overlay-text {
    color: white; /* Change text color as needed */
    font-size: 24px; /* Change font size as needed */
    /* Add additional styling for the text if necessary */
  }

/* Add this style to your stylesheet or in a style tag in your HTML */
div.p-datepicker-buttonbar button[aria-label="Today"] {
    display: none;
}

.p-datatable-loading-icon{
    color: #da474e;
}

.call-card .p-card-content:nth-child(3) {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

@media screen and (max-width: 850px) {
    .applicantContainer {
      flex-direction: column !important;
      width: 100% !important;
      gap: 0 !important;
    }
  
    .applicantDivs{
        width: 100% !important;
      flex-direction: column !important;
  
    }
}

.popupTableData{
    width: 85% !important;
    min-height: 50vh !important;
}