.applicant-cards::-webkit-scrollbar,
.filterPopup::-webkit-scrollbar {
  width: 10px !important;
}

.applicant-cards::-webkit-scrollbar-track,
.filterPopup::-webkit-scrollbar-track {
  background-color: #ffffff1c !important;
}

.applicant-cards::-webkit-scrollbar-thumb,
.filterPopup::-webkit-scrollbar-thumb {
  background-color: #ffffff1c !important;
  border-radius: 5px;
}

@media screen and (max-width: 850px) {
  .applicantContainer {
    flex-direction: column !important;
  }
  .applicantDivs {
    width: 100% !important;
  }
  .searchContainer {
    width: 90% !important;
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 650px) {
  .piplelineTitle {
    display: none !important;
  }
  .searchContainer,
  .piplelineTitleMob {
    justify-content: center !important;
  }
}

@media screen and (min-width: 650px) {
  .piplelineTitleMob {
    display: none !important;
  }
}

.applicantDivs p,
div {
  word-wrap: break-word;
}

.applicantContainer [aria-pressed="true"] {
  background: #0f173e !important;
}

.p-datepicker .p-timepicker,
.p-datepicker .p-timepicker button {
  padding: 0 !important;
}
