@import url(https://db.onlinewebfonts.com/c/288be5cb6f0a89e2a430d70610f6405b?family=DINCondensedC);

body {
  margin: 0;
  /* padding: 64px 15% 0; */
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom right, #060706 60%, #062A47);

  /* background: grey; */
  /* background-repeat: no-repeat; */
  /* background: linear-gradient(120deg, #17244E 50%, #1D3867, #07589A 100%); */
  /* background: linear-gradient(to bottom right, #060706 40%, #062A47); */
}

header {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0rem;
  z-index: 100;
  /* background-color: #0f173e; */ 
  /* background: linear-gradient(to bottom right, #17244E, #1D3867, #07589A, #049E80); */
  color: white;
}

.sticky {
  background-color: black; /* Change to your desired background color */
  transition: background-color 0.3s; /* Add a smooth transition for background color changes */
  color: white;
}

/* @media (max-width: 450px) {
    header {
      flex-direction: column-reverse;
      text-align: center;
      gap: 1rem;
    }
} */

.logout-button-container {
  display: flex;
  align-items: center;
}

/* Styles for small screens (e.g., mobile devices) */
/* @media (max-width: 450px) {
  .logout-button-container {
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
  }
} */

nav {
  display: flex;
  gap: 10px;
}

@keyframes in-keyframes {
  0% {
    opacity: 0;
    translate: 0 100%;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

.animate-in {
  animation-name: in-keyframes;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* wave link styles */
body .react-bubbly-transitions__bubbly-link {
  padding: 0;
  outline: none;
}

body .react-bubbly-transitions__bubbly-link.active {
  position: relative;
  color: #da474e;
}

body .react-bubbly-transitions__bubbly-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background: #da474e;
}

@media (min-width: 1200px) {
  .p-dialog {
    width: 45vw; 
  }
  /* .editForm.p-dialog{
    width: 45vw; 
  } */
}

@media (max-width: 767px) {
  .p-dialog {
    width: 99vw!important;
    max-height: 100% !important;
    top: 0 !important;
  }

}

@media (min-width: 768px) and (max-width: 980px) {
  .p-dialog {
    width: 80vw!important;
  }
}

.header-container-with-csv-export{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.applicant-cards{
  overflow-x: auto !important;  /* Enables horizontal scrollbar */
  /* white-space: nowrap !important;   */
  /* Optional styles for width and height */
  width: 1000px;  /* Adjust width as needed */
  /* height: 84.5vh;   */
}

.react-datepicker__input-container input{
  padding: 10px !important;
  font-size: 14px !important;
  border-radius: 5px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container{
  width: 150px !important;
}

.loginPage input,
.breadCrumbsText{
  background: rgba(255, 255, 255, 0.06); 
  box-shadow : -1px -2px 0px #ffffff40 !important;  
  border: none ;
  /* filter: blur(0px); */
  backdrop-filter: blur(10px);
  color: white
}

@media (max-width: 750px) {
  .loginPageImage{
    display: none !important;
  }

  /* #root::before{
    height: 108vh !important;
  } */
}

/*popup*/

  
::-webkit-scrollbar-track,
::-webkit-scrollbar-track
 {
    background-color: #ffffff1c !important;
  }
  
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb
 {
    background-color: #ffffff1c !important; 
    border-radius: 5px; 
  }
  .p-dialog{
    /* z-index: 1000000 !important; */
    top: 20px;
  }
  
  .p-dialog.p-component,
  .p-datatable-table{
    backdrop-filter: blur(40px) !important;
  }

  .p-dialog:not(.formDiv) .p-dialog-content{
    background: #ffffff1c !important;
    color: white ;
  }

  .stagesSection{
    color: black !important;
    border-radius: 10px !important;
  }
  
  .p-dialog:not(.formDiv) .p-dialog-header{
    background: #ffffff1c !important;
    color: white !important;
    padding: 5px !important;

  }

  .p-datatable-header,
  .p-datatable-wrapper{
    background: black !important;
  }

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: transparent !important;
}

.p-datatable .p-datatable-thead > tr > th{
  background : black !important
}

/* :not(.formDiv) input{
  background: #151415 !important;
  color: white !important;
  border: 1px solid grey !important;
}

:not(.formDiv) .p-dropdown-label.p-inputtext{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background: #151415 !important;
}

:not(.formDiv) .p-multiselect.p-component,
:not(.formDiv) .p-multiselect-header,
:not(.formDiv) .p-multiselect-items-wrapper,
:not(.formDiv) .p-dropdown-header,
:not(.formDiv) .p-dropdown-items-wrapper{
  background: #151415 !important;
}

:not(.formDiv) .p-multiselect-item span:nth-child(2){
  color: white;
}

:not(.formDiv) .p-dropdown-item{
  color: white !important;
}

:not(.formDiv) .p-dropdown-item:hover{
  color: black !important;
}

:not(.formDiv) .p-multiselect-item:hover span:nth-child(2){
  color: black !important;

}
:not(.formDiv) .p-multiselect-item .p-avatar-text{
  color: black !important;
}

:not(.formDiv) .p-dropdown-trigger{
  background: #151415 !important;

} */

:not(.formDiv) .p-overlaypanel.p-component{
  backdrop-filter: blur(50px) !important;
  background: #0000004f !important;
}

:not(.formDiv) .p-paginator.p-component .p-dropdown-label.p-inputtext{
   color: white !important;
}

header{
  /* background-color: black !important */
}

.p-dialog-footer{
  background-color: #252525 !important;
}

.p-multiselect-label{
  color: black;
}

.p-dialog-footer [aria-label="No"]{
  color: white !important;
  background: black !important;
}


.p-dialog-title{
  padding: .5rem 1rem;
}