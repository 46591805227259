.p-datatable {
    position: relative;
    border: 1px solid #0f173e;
    border-radius: 1rem;
}

.sync-button-container{
    display: flex;
    justify-content: end;
    margin-bottom: 1.5rem;
}

.sync-button{
    background: #0f173e;
    border: 1px solid #0f173e;
}

.sync-button:enabled:hover{
    background: #0f173ed8;
    border: 1px solid #0f173ed8;
}

.view-button{
    background: #0f173e;
    border: 1px solid #0f173e;
}

.view-button:enabled:hover{
    background: #11183b;
    border: 1px solid #11183b;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #0f173e;
    color: #0f173e;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled) {
    background: #0f173e;
    color: #0f173e;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #0f173e;
    color: #0f173e;
}

.p-inputtext:enabled:hover {
    border-color: #0f173e;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #0f173e;
    border-color: #0f173e;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #0f173e;
    border-color: #0f173e;
    color: #0f173e;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #0f173e;
    border-color: #0f173e;
    color: #0f173e;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #0f173e;
    border-color: #0f173e;
    color: #0f173e;
}

.p-datatable .p-datatable-header {
    background: #0f173e;
    color: #343a40;
    border: 1px solid #0f173e;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #0f173e;
    transition: box-shadow 0.2s;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #0f173e;
    color: #0f173e;
}

.p-input-icon-left, .p-input-icon-right {
    position: relative;
    display: inline-block;
    width: 100%;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem;
    width: 100%;
}

.p-button.p-button-sm .p-button-icon {
    font-size: 1.3rem;
}

.red-lock{
    color: red!important;
}

.p-togglebutton.p-button.p-highlight {
    background: red;
    border-color: red;
    color: #ffffff;
}
.p-togglebutton.p-button {
    background: green;
    border: 1px solid #ced4da;
    color: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #0f173e;
    background: #0f173e;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #0f173ed8;
    background: #0f173ed8;
    color: #ffffff;
}

.p-button.p-button-text {
    background-color: transparent;
    color: #0f173e;
    border-color: transparent;
}

.p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(99, 102, 241, 0.04);
    color: #0f173ed8;
    border-color: transparent;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
    color: #ffffff;
    background: #da474e;
    border: 1px solid #da474e;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #0f173e;
    color: #ffffff;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled) {
    background: #0f173e;
    color: #ffffff;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #ffffff;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-icon:hover {
    color: #ffffff;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled){
    background: #0f173e;
    color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #0f173e;
    color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #0f173e;
    border-color: #0f173e;
    color: #ffffff;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #ffffff;
    background: #0f173e;
    transition: box-shadow 0.2s;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #ffffff;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #0f173e;
}
