

@media screen and (max-width: 650px) {
  .inputContainer {
    flex-wrap: wrap !important;
  }
}

.submissionList .p-datatable-wrapper{
  border-radius: 15px !important;
}

.submissionList.p-dialog{
  width: 55vw!important; 
}

@media screen and (max-width: 1350px) {
  .inputContainerFlex {
      flex-wrap: wrap !important;
      flex-direction: column !important;
      width: 100% !important;
      align-items: start !important;
      gap: 1rem;
  }
  .inputContainerFlex:hover {
    outline: none !important;
}
  .inputContainerDiv {
      width: 100% !important;
  }

  .customDivider{
    display: none !important;
  }
}

.dropdownDiv{
  visibility: hidden !important;
}

.editCosignerForm .p-dialog-content{
  padding-bottom: 0 !important;
}

@media (min-width: 1200px) {
  .auditList.p-dialog{
    width: 80vw!important; 
   padding: 0 !important;
   /* height: 100vh !important; */
  }
  .auditList .p-dialog-content {
    min-height: 90vh !important;
}
.auditList .p-dialog-header {
  padding: 10px 25px !important;
}
}
.profileEditForm .p-dialog-content{
  padding-bottom: 0 !important;
}

@media (min-width: 1200px) {
  .profileEditForm.p-dialog{
    width: 60vw!important; 
   padding: 0 !important;
  }

}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  padding: 0.15rem 1.25rem !important;
}

.applicantProfile .p-dropdown .p-dropdown-trigger{
  width: auto !important;
}