body {
  margin: 0;
}

#react-bubbly-transitions__bubbles {
  --size: 200vw;
}

@media only screen and (min-width: 768px) {
  #react-bubbly-transitions__bubbles {
    --size: 125vw;
  }
}

.react-bubbly-transitions__first,
.react-bubbly-transitions__second {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 50%;
  translate: -50% 100%;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  animation-timing-function: ease-in-out;
}

.react-bubbly-transitions__first {
  animation-name: bubble-move;
}

.react-bubbly-transitions__second {
  animation-name: bubble-second-move;
}

@keyframes bubble-move {
  20% {
    border-radius: var(--size);
  }
  50%,
  100% {
    translate: -50% 0;
    border-radius: 0;
  }
}

@keyframes bubble-second-move {
  30% {
    translate: -50% 100%;
  }
  50% {
    border-radius: var(--size);
  }
  100% {
    translate: -50% 0;
    border-radius: 0;
  }
}

/* bubbly Link */
.react-bubbly-transitions__bubbly-link {
  background: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}
