

@media screen and (max-width: 850px) {
    .inputContainer {
        flex-wrap: wrap !important;
        flex-direction: column !important;
        width: 100% !important;
    }

    .inputContainerDiv {
        width: 100% !important;
    }
    .historyForm{
        margin-right: 0% !important;
      }
  }

  textarea {
    resize: vertical !important;
  }



  .historyForm.p-dialog .p-dialog-content {
    padding: 0 !important;
  }

  @media (min-width: 1200px) {
    .historyForm.p-dialog{
      width: 65vw!important; 
     padding: 0 !important;
    }
    .historyForm{
        margin-right: 10% !important;
      }
      .cosignerForm {
        width: 70vw !important;
      }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    padding: 0.15rem 1.25rem !important;
  }

  