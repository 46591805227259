.numberOneCalls {
    animation: zoomCallsEffect 2s ease-in-out forwards; 
}

.numberOneApps{
    animation: zoomAppsEffect 2s ease-in-out forwards; 
}

@keyframes zoomCallsEffect {
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.5); 
    }
    100% {
        transform: scale(1); 
    }
}

@keyframes zoomAppsEffect {
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.5); 
    }
    100% {
        transform: scale(1); 
    }
}

@media screen and (max-width: 850px) {
    .applicantContainer {
      flex-direction: column !important;
      width: 100% !important;
    }
  
    .applicantDivs{
        width: 100% !important;
      flex-direction: column !important;
    text-align: center !important;
    }
}

