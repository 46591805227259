.settings-card-container{
    display: grid;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    position: relative;
}

@media screen and (min-width: 1024px) {
    .settings-card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1rem;
    }
}

/* For even larger screens (e.g., large TVs) */
@media screen and (min-width: 1440px) {
    .settings-card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1rem;
    }
}

.card-container {
    flex-grow: 1;
    flex-basis: 200;
    background: var(--surface-card);
    /* width: 48%;
    border-radius: 10px;
    margin-bottom: 1rem;
    justify-content: center!important;
    display: flex!important; */
}

@media screen and (min-width: 1024px) {
    .card-container {
        width: 48%;
        border-radius: 10px;
        margin-bottom: 1rem;
        justify-content: center!important;
        display: flex!important;
    }
}

/* For even larger screens (e.g., large TVs) */
@media screen and (min-width: 1440px) {
    .card-container {
        width: 48%;
        border-radius: 10px;
        margin-bottom: 1rem;
        justify-content: center!important;
        display: flex!important;
    }
}

.card-footer{
    display: flex;
    justify-content: end;
    gap: .5rem;
}

.card{
    width: 100%;
    background-color: #d9d9d9;
}

.language-multiselect{
    width: 100%;
}

.form-card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}
.form-justify-content-center {
    justify-content: center!important;
}
.form-flex {
    display: flex!important;
}

.form-gap-2 {
    gap: 0.5rem!important;
}
.form-flex-column {
    flex-direction: column!important;
}

.settings-add-button {
    background: #0f173e;
    border: 1px solid #0f173e;
}

.settings-add-button:hover {
    color: #ffffff;
    background: #0f173ed8!important;
    border: 1px solid #0f173ed8!important;
}


.user-card{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f4;
}

.user-form{
    /* width: 650px; */
    padding: 20px;
    /* border: 1px solid #ccc; */
    /* background-color: #fff; */
    /* border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
}

.two-fields-flex{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
}

/* @media screen and (max-width: 650px) {
    .two-fields-flex {
      flex-direction: column;
    }
  } */


.lable-input-group{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    width: 48%;
}

.email-lable-input-group{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.full-input{
    width: 100%;
}

.phone-lable{
    flex: 0 0 70%;
}

.extension-lable{
    flex: 0 0 20%;
}

.accordation{
    margin-top: 2rem;
}

.permission-row{
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-around;
    gap: 3rem;
}

.lable-checkbox{
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
}

.form-header{
    margin-bottom: 2rem;
}

.save-buttom{
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 1.5rem;
}

.password-icon{
    font-size: 1.2rem;
}

.password-icon:hover{
    cursor: pointer;
    color: #da474e!important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5) as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of everything */
  }

.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
    background: transparent;
    color: #ffffff;
    border-color: transparent;
}
.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    /* background: transparent; */
    color: #ffffff;
    border-color: transparent;
}

.p-card.p-component{
    box-shadow: rgba(255, 255, 255, 0.25) -1px -2px 0px;
    background: rgb(255 255 255 / 8%);
    backdrop-filter: blur(2px);
    color: white;

}

.card-container{
    background: transparent !important;
}

.settings-card-container button {
    background-color: white !important;
    color: black !important;
}
.settings-card-container button:hover{
    background-color: black !important;
    color: white !important;
}