.appItem p{
    color: white ;
    font-size: 14px;
}

.appItem button{
    font-size: 12px;
    padding: 5px 10px;
}

.appItem{
    border-radius: 10px;
    /* height: 160px !important; */
}

.appSection::-webkit-scrollbar,
.applicantPopup .p-dialog-content::-webkit-scrollbar{
    width: 10px !important; 
  }
/*   
.appSection::-webkit-scrollbar-track,
.applicantPopup .p-dialog-content::-webkit-scrollbar-track
 {
    background-color: #ffffff1c !important;
  }
  
  .appSection::-webkit-scrollbar-thumb,
  .applicantPopup .p-dialog-content::-webkit-scrollbar-thumb
 {
    background-color: #ffffff1c !important; 
    border-radius: 5px; 
  }
  
  .applicantPopup{
    backdrop-filter: blur(30px) !important;
  }

  .applicantPopup.p-dialog .p-dialog-content{
    background: #ffffff1c !important;
    color: white !important;
  }

  .applicantPopup.p-dialog .p-dialog-header{
    background: #ffffff1c !important;
    color: white !important;
    padding: 5px !important;

  } */

  @media (min-width:1200px) {
    .applicantPopup.p-dialog{
      width: 55vw!important; 
     padding: 0 !important;
    }

    .checklistTemplate.p-dialog{
      width: 50vw !important;
    }
  }

  @media (max-width:1200px) {
    .applicantPopup.p-dialog{
      width: 70vw!important; 
     padding: 0 !important;
    }
  }

  .loadingAppItem {
    margin: 10px;
    width: 280px;
    height: 170px;
    border: solid 1px #B1B1B1;
    padding: 15px;
    cursor: pointer;
    background: #ffffff1c;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}

.loadingAppItem::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%); */
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: shimmer 2s infinite;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@media screen and (max-width: 850px) {
 .applicantPopup  .applicantContainer {
    flex-direction: column !important;
    width: 100% !important;
    justify-content: center !important;
    gap: 1rem !important;
  }

  .applicantPopup .applicantDivs{
      width: 100% !important;
    flex-direction: row !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: .5rem !important;
  }
  
  .commentSectionDiv{
    width: 80% !important;
  }

  .commentSectionContainer{
    justify-content: center !important;
  }

  .fullWidth{
    width: 100% !important;
  }
  .applicantPopup.p-dialog{
    width: 99vw!important; 
   padding: 0 !important;
  }

  .checkListSectionDiv{
    flex-direction: column !important;
  }
}

@media screen and (max-width: 650px) {
  
  .commentSectionContainer{
    justify-content: space-between !important;
  }

  .descriptionEditorDiv{
    height: 170px !important;
  }

  .ql-editor{
    height: 100px !important;
  }

}

.applicantDivs p, div{
  word-wrap: break-word;
}
/* 
.p-multiselect-header,
.p-multiselect-panel{
  background: #1A2B53 !important;
}

.p-multiselect-item,
.p-multiselect-empty-message{
  color: white !important;

}
.p-multiselect-item:hover,
.p-multiselect-item.p-highlight{
  color: black !important;
} */


.activity .p-mention-item {
  padding: 2px !important;
  width: 100% !important;

}
.activity .mention-input textarea{
  background: transparent !important;
  color: white !important;
  border: 1px solid grey !important;
}

.activity .profile-input textarea{
  background: white !important;
  color: black !important;
  width: 100% !important;
}

.edit-mention-input textarea,
.mention-input textarea{
  width: 100% !important;
}

.mention-input,
.profile-input{
  width: 100% !important;

}
.checklist-input {
  width: 100% !important;
}

@media screen and (max-height : 850px){
  .kanbanContainer{
       height : calc(95vh - 40px)  !important;
}
}

@media screen and (min-height : 850px){
  .applicantCardInnerDiv{
    height: calc(100vh - 180px) !important;
  }
  .applicantCardSectionDiv{
    width: calc(100vh - 75vh) !important;
    height: calc(100vh - 20vh)!important;
  }
  .appItem {
    min-height: calc(100vh - 85vh) !important;    
}

.appSection {
    min-height: calc(100vh - 28vh) !important;
}
}

/* .dueDate input{
  border: none !important;
  color: white !important;
  background: transparent;
  font-size: 12px !important;
  margin-right: -100px !important;
} */

.p-progressbar.p-component{
  font-size: 12px !important;
  height: 18px !important;
}

.checkListItem:hover{
  background: #383e94 !important;
}

.checkListDiv .p-datepicker table td,
.p-datepicker table td{
  padding: 0 !important;
}

/* .quill button, 
.quill .ql-stroke 
.quill span,
.quill span span,
 .ql-fill {
  color: white !important;
} */

.ql-toolbar {
  background: white !important;
}

.descriptionDiv{
  margin-left: 1.5rem !important;
}

.descriptionDiv a{
  color: rgb(47, 198, 35) !important;
}

.p-overlaypanel  .p-datepicker table td{
  padding: 0 !important;
}

.customFieldDiv .p-component,
.customFieldDiv .p-dropdown-label{
  padding: .5rem !important;
}

.customFieldDiv .p-inputwrapper{
  padding: 0 !important;
}

.ql-tooltip {
  left: 0 !important;
  background: #243F6D !important;
  color: white !important;
}

.ql-tooltip a {
  color: #03D932 !important;
}

.ql-editor {
  background: whitesmoke !important;
  color: black !important;
  min-height: 70px;
}
