/* Default margin for mobile and small devices */
.table-container {
    margin: 0.5rem;
    padding-bottom: 0.5rem;
}

/* For tablets and larger screens */
@media screen and (min-width: 768px) {
    .table-container {
        margin: 1rem;
        padding-bottom: 1rem;
    }
}

/* For larger screens (e.g., laptops and TVs) */
@media screen and (min-width: 1024px) {
    .table-container {
        margin: 2rem;
        padding-bottom: 2rem;
    }
}

/* For even larger screens (e.g., large TVs) */
@media screen and (min-width: 1440px) {
    .table-container {
        margin: 2rem;
        padding-bottom: 2rem;
    }
}
