

.containerDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;

}

@media screen and (max-width: 850px) {
    .profileContainer {
      flex-direction: column !important;
    }
    .profileViewDivs{
        width: 100% !important;
    }
  }

.profileViewDivs p, div{
    word-wrap: break-word;
}

.profileViewDivs button {
  background-color: white !important;
  color: black !important;
}

.profileViewContainer,
.workExperienceContainer,
.personalInfoContainer,
.propertyOwnedContainer,
.profileActivityContainer,
.shoppingForContainer,
.typeofHomeContainer,
.savingsAssetsContainer,
.profileCosignerContainer{
  box-shadow: rgba(255, 255, 255, 0.25) -1px -2px 0px;
  background: rgb(255 255 255 / 8%);
  backdrop-filter: blur(2px);
  color: white;

}

.profileContainer .p-dropdown-label.p-inputtext,
.profileContainer .p-dropdown.p-component {
  background-color: transparent !important;
  color: white !important;
}

.profileViewContainer .p-avatar.p-component,
.profileAddressContainer .p-avatar.p-component
 {
  color: black !important;
}