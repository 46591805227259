.chart-card {
    position: relative;
    background-color: #ffffff!important;
  }
  
  .chart-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Make sure the overlay is on top of the chart */
  }

  .pi-spinner{
    color: #da474e;
  }
  
