.purchase-form-group{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
}

.form-field-container{
    width: 45%;
}

.form-field-container-date{
    width: 100%;
}

.purchase-dropdown{
    width: 100%;
}

.mb-2{
    margin-bottom: .5rem;
    padding-left: 1rem;
}

.mt-1{
    margin-top: 1rem;
}

.p-inputnumber-input {
    width: 100%;
}

.p-button {
    color: #ffffff;
    background: black ;
    border: none;
}


.p-button:enabled:hover {
    color: #ffffff;
    background: black ;
    border: none;
}